export default {
  translation: {
    navbar: {
      help:      'HELP',
      inputData: 'INVOERGEGEVENS',
      logout:    'Uitloggen',
      login:     'Inloggen'
    }
  }
}
